<template>
  <page-template>
    <main-block>
      <block-head :title="$t('Appointments')" :description="$t('pages.ap_agent_profile.appointments_desc')">

      </block-head>
      <block-content>
        <card no-padding stretch>
          <template v-slot:grouped>
            <div class="card-inner position-relative card-tools-toggle">
              <card-header title="">
                <card-tools>
                  <bulk-action :actions="{export: 'Export'}" @perform-action="performBulkAction"></bulk-action>
                </card-tools>
                <card-tools>
                  <div class="form-inline flex-nowrap gx-3">
                    <div class="form-wrap">
                      <b-select class="form-select form-select-md" v-model="leads.filters.assignment_filter" v-on:change="getLeads">
                        <option value="all">{{ $t('Assignment Filter') }}</option>
                        <option value="assigned">{{$t('Assigned Leads')}}</option>
                        <option value="unassigned">{{$t('Unassigned Leads')}}</option>
                      </b-select>
                    </div>
                    <div class="form-wrap ml-4" v-if="leads.filters.assignment_filter !== 'unassigned'">
                      <b-select class="form-select form-select-md" v-model="leads.filters.opened_filter" v-on:change="getLeads">
                        <option value="all">{{ $t('Opened Filter') }}</option>
                        <option value="opened">{{$t('Opened')}}</option>
                        <option value="not_opened">{{$t('Not Opened')}}</option>
                      </b-select>
                    </div>
                  </div>
                </card-tools>
                <card-tools class="mr-n1">
                  <btn-toolbar>
                    <btn-toolbar-toggle-wrap id="leads_toolbar">
                      <li>
                        <nk-button is-link type=""
                                   is-icon-only-button=""
                                   class="search-toggle"
                                   v-on:click="searchBarActive=true"
                                   :class="{active: searchBarActive}">
                          <nio-icon icon="ni-search"></nio-icon>
                        </nk-button>
                      </li>
                      <li class="btn-toolbar-sep"></li>
                      <li>
                        <drop-down icon="ni ni-setting" title="" size="xs" direction="right">
                          <ul class="link-check">
                            <li><span>{{ $t('Order') }}</span></li>
                            <li :class="{active: leads.order === 'asc'}"><a href="javascript:;" v-on:click="[leads.order='asc', getLeads(true)]">{{ $t('ASC') }}</a></li>
                            <li :class="{active: leads.order === 'desc'}"><a href="javascript:;" v-on:click="[leads.order='desc', getLeads(true)]">{{ $t('DESC') }}</a></li>
                          </ul>
                        </drop-down>
                      </li><!-- li -->
                    </btn-toolbar-toggle-wrap>
                  </btn-toolbar>
                </card-tools>
              </card-header>
              <card-search
                  :placeholder="$t('Search by')+': '+$t('Lead id')+' | '+$t('Name')+' | '+$t('Email')+', & '+$t('Advisor')+' '+$t('Name')"
                  :is-active="searchBarActive"
                  @do-search="searchRecords"
                  :search-term="searchFilter"
                  @close-search="[searchBarActive=false, searchRecords('')]"></card-search>
            </div>
            <div class="d-flex py-5 justify-center"  v-if="spinners.gettingLeads">
              <ion-spinner></ion-spinner>
            </div>
            <template v-else>
              <div class="card-inner p-0">
                <ion-list v-if="$isHybridPlatform">
                  <template v-for="lead in leads.data" :key="'ld'+lead.id">
                    <ion-item button
                              v-on:click="setLeadTempInfo(lead)"
                              lines="full" style="--border-color:#e3eaf5;--padding-bottom: 8px;--padding-top: 8px;">
                      <ion-avatar slot="start" class="user-avatar">
                        <img v-if="lead.dp" :src="lead.dp" alt="Avatar"/>
                        <span v-else class="text-uppercase">{{ lead.initials }}</span>
                      </ion-avatar>
                      <div>
                        <ion-label class="fw-500" color="dark">{{ lead.first_name+' '+lead.last_name}}</ion-label>
                        <ion-text color="soft" class="medium d-block">#{{ lead.lead_number }}</ion-text>
                        <span v-if="lead.assigned_to.profile_id" :style="{color: lead.assigned_to.status_color}">{{ $t(lead.assigned_to.status_text) }}</span>
                        <span v-else :style="{color: lead.status_color}">{{ $t(lead.status_text) }}</span>
                      </div>
                      <ion-badge color="primary" slot="end" v-if="lead.assigned_to.profile_id">Assigned</ion-badge>
                    </ion-item>
                  </template>
                </ion-list>
                <special-table v-else>
                  <table-row is-heading>
                    <table-col has-check-box>
                      <b-check-box id="apg_apt_s_all_cb" v-model="allSelected"></b-check-box>
                    </table-col>
                    <table-col break-point="md">
                      <span class="sub-text">Lead</span>
                    </table-col>
                    <table-col break-point="md">{{$t('Address')}}</table-col>
                    <table-col break-point="md">{{$t('Assigned to')}}</table-col>
                    <table-col break-point="lg">{{$t('Current status')}}</table-col>
                    <table-col class="nk-tb-col-tools"></table-col>
                  </table-row>

                  <table-row v-for="lead in leads.data" :key="'ld'+lead.id">
                    <table-col has-check-box>
                      <b-check-box :id="'apg_apt_s_cbk'+lead.AL_id" v-model="lead.selected" :key="'apg_apt_s1_cbk'+lead.AL_id"></b-check-box>
                    </table-col>
                    <table-col break-point="md" class="lh-n">
                      <user-small-card
                          :user-name="lead.first_name+' '+lead.last_name"
                          :user-email="lead.email" :image="lead.dp" :initials="lead.initials" bg-color="info"
                          class="align-start">
                        <template v-slot:more_info>
                          <div class="lh-n small">
                            <div class="d-flex align-items-center">
                              <nio-icon icon="ni-call-alt-fill" class="mr-1"></nio-icon>
                              <span>{{ lead.phone_no }}</span>
                            </div>
                            <div class="text-primary">Lead #: {{ lead.lead_number}}</div>
                          </div>
                        </template>
                      </user-small-card>
                    </table-col>
                    <table-col break-point="md">{{ lead.post_code }}<span class="sub-text">{{lead.city+' '+lead.province}}</span> </table-col>
                    <table-col break-point="md">
                      <user-small-card v-if="lead.assigned_to.profile_id"
                                       :user-name="lead.assigned_to.first_name+' '+lead.assigned_to.last_name"
                                       :user-email="lead.assigned_to.email" :image="lead.assigned_to.dp" :initials="lead.assigned_to.initials" bg-color="primary"
                                       class="align-start">
                        <template v-slot:more_info>
                          <div class="lh-n mt-n1 small">
                            <div class="d-flex align-items-center">
                              <nio-icon icon="ni-call-alt-fill" class="mr-1"></nio-icon>
                              <span>{{ lead.assigned_to.phone_number }}</span>
                            </div>
                            <div>
                              <span class="fw-500">{{ $t('Assign date') }}:</span>
                              <span class="ml-1">{{ lead.assigned_to.assigned_date }}</span>
                            </div>
                            <div>
                              <span class="fw-500 mr-1">{{ $t('Opened')+' '+$t('at') }}:</span>
                              <span v-if="lead.assigned_to.opened_at">{{lead.assigned_to.opened_at}}</span>
                              <span v-else class="text-danger">{{ $t('Not Opened') }}</span>
                            </div>
                            <router-link :to="{name: 'apAgentAdvisorProfile', params:{id: lead.assigned_to.profile_id}}" target="_blank">{{ $t('view profile') }}</router-link>
                          </div>
                        </template>
                      </user-small-card>
                      <template v-else-if="lead.status_id !== 9">
                        <div class="text-danger">{{ $t('Not yet assigned') }}</div>
                        <nk-button type="primary" outline size="sm" v-on:click="$router.push({name: 'apAgentLeadDetails', params:{id:lead.AL_id}})">{{ $t('Assign') }}</nk-button>
                      </template>
                    </table-col>
                    <table-col v-if="lead.assigned_to.profile_id" break-point="lg" :style="{color: lead.assigned_to.status_color}">{{ $t(lead.assigned_to.status_text) }}</table-col>
                    <table-col v-else break-point="lg" :style="{color: lead.status_color}">{{ $t(lead.status_text) }}</table-col>
                    <table-col class="nk-tb-col-tools">
                      <ul class="nk-tb-actions gx-1">
                        <li>
                          <drop-down-list>
                            <list-item icon="ni-eye" :text="$t('View Details')" :to="{name: 'apAgentLeadDetails', params:{id:lead.AL_id}}"></list-item>
                          </drop-down-list>
                        </li>
                      </ul>
                    </table-col>
                  </table-row>

                </special-table>
              </div>
              <div class="card-inner" v-if="leads.totalRecords > 10">
                <pagination
                    v-model="leads.page"
                    :records="leads.totalRecords"
                    :per-page="10"
                    @paginate="paginationCallback"
                    theme="bootstrap4"
                    :options="{chunk: 5, texts:{count: '||'}}"/>
              </div>
            </template>
          </template>
        </card>
      </block-content>
    </main-block>
    <leads-csv-export-modal
        :selected-leads="leadsCSVExport.selectedLeads"
        :modal-open="leadsCSVExport.modalOpen"
        @modal-close="leadsCSVExport.modalOpen=false"></leads-csv-export-modal>
    <ion-popover
        :is-open="isPopoverOpen"
        @didDismiss="isPopoverOpen=false"
        css-class="popover_class">
      <ion-list>
        <ion-item style="--padding-top: 10px" lines="none">
          <div>
            {{ leadTemp.first_name+' '+leadTemp.last_name}}<br>
            {{ leadTemp.post_code }}, {{leadTemp.city+' '+leadTemp.province}}
          </div>
        </ion-item>
        <ion-item lines="none">{{ leadTemp.email }}</ion-item>
        <ion-item lines="none" v-if="leadTemp.assigned_to.profile_id" button
                  v-on:click="[$router.push({name: 'apAgentAdvisorProfile', params:{id: leadTemp.assigned_to.profile_id}}), isPopoverOpen=false]"
                  class="mb-2">
          <div class="lh-n">
            <ion-label color="soft">{{ $t('Assigned to')}}</ion-label>
            <div>
              {{leadTemp.assigned_to.first_name+' '+leadTemp.assigned_to.last_name}}<br>
              {{leadTemp.assigned_to.email}}
            </div>
          </div>
        </ion-item>
        <ion-item style="--padding-bottom: 10px;" lines="full">
          <div class="lh-n">
            <ion-label color="soft">{{ $t('Current status')}}</ion-label>
            <span v-if="leadTemp.assigned_to.profile_id" :style="{color: leadTemp.assigned_to.status_color}">{{ $t(leadTemp.assigned_to.status_text) }}</span>
            <span v-else :style="{color: leadTemp.status_color}">{{ $t(leadTemp.status_text) }}</span>
          </div>
        </ion-item>
        <ion-item lines="full" class="text-primary" button v-on:click="[$router.push({name: 'apAgentLeadDetails', params:{id:leadTemp.AL_id}}),isPopoverOpen=false]">
          <ion-icon :icon="eyeIcon" slot="start"></ion-icon>
          <ion-label class="fw-500">{{ $t('View Details')}}</ion-label>
        </ion-item>
        <ion-item class="text-primary" :href="'tel:'+leadTemp.phone_no" v-on:click="isPopoverOpen=false">
          <ion-icon :icon="phoneCallIcon" slot="start"></ion-icon>
          <ion-label class="fw-500">{{ $t('Call')}}</ion-label>
        </ion-item>
      </ion-list>
    </ion-popover>
  </page-template>
</template>

<script>
import {
  IonSpinner,
  isPlatform, onIonViewWillEnter,IonList,
  IonItem,
  IonAvatar,
  IonLabel,
  IonText,
  IonPopover,
  IonIcon,
  IonBadge,
} from '@ionic/vue';
import {defineComponent, reactive, ref, watch, computed} from 'vue';
import {BlockContent, BlockHead, MainBlock} from "@/@core/layouts";
import axios from "@/libs/axios"
import {Card, CardHeader, CardSearch, CardTools} from "@core/components/cards";

import { BtnToolbar, BtnToolbarToggleWrap } from "@core/components/btn-toolbar"
import "bootstrap"
import {DropDown, DropDownList} from "@core/components";

import Pagination from "v-pagination-3"
import {SpecialTable, TableCol, TableRow} from "@core/components/special-table";
import UserSmallCard from "@/views/components/user-cards/UserSmallCard";
import ListItem from "@core/components/dropdown-list/ListItem";
import BSelect from "@core/components/bp-form/components/BSelect";
import NkButton from "@core/components/button/NkButton";
import {callOutline, eyeOutline} from "ionicons/icons";
import PageTemplate from "@core/layouts/page/PageTemplate";
import BulkAction from "@/views/components/BulkAction";
import BCheckBox from "@core/components/bp-form/components/BCheckBox";
import useCommonFunc from '@core/comp-functions/common'
import LeadsCsvExportModal from "@/views/leads-export/components/LeadsCsvExportModal";

export default defineComponent({
  components: {
    LeadsCsvExportModal,
    BCheckBox,
    BulkAction,
    PageTemplate,
    NkButton,
    BSelect,
    ListItem,
    DropDownList,
    UserSmallCard,
    SpecialTable,
    TableCol,
    TableRow,
    CardSearch,
    CardTools,
    BlockHead,
    DropDown,
    BtnToolbar, BtnToolbarToggleWrap,
    CardHeader,
    Card,
    BlockContent,
    MainBlock,
    IonSpinner,
    Pagination,
    IonList,
    IonItem,
    IonAvatar,
    IonLabel,
    IonText,
    IonPopover,
    IonIcon,
    IonBadge,
  },
  setup() {

    const isDesktop = isPlatform('desktop')
    const {getSelectedRecordsForBulkAction, selectAll} = useCommonFunc()

    let leads = reactive({
      data: [],
      filters:{
        assignment_filter: 'all',
        opened_filter: 'all',
      },
      order: 'desc',
      page: 1,
      totalRecords: 0,
    })
    let searchFilter  = ref('');

    let spinners = reactive({
      gettingLeads: false,
    })
    let leadsCSVExport = reactive({
      modalOpen: false,
      selectedLeads: [],
    })

    let searchBarActive = ref(false)

    const paginationCallback = async () => {
      await getLeads(true)
    }

    watch(searchFilter, () => getLeads(true))

    const searchRecords = (search) => {
      searchFilter.value = search
    }


    let cancelToken = false
    const getLeads = () => {
      if(false !== cancelToken){
        cancelToken()
        cancelToken = false
      }
      spinners.gettingLeads = true
      leads.data.splice(0)
      axios.post('/api/ap_agent/appointments/get',
          Object.assign({page: leads.page, order: leads.order, search: searchFilter.value}, leads.filters),
          {cancelToken: new axios.CancelToken( c => { cancelToken = c})}
      ).then( resp => {
        if(resp.data.success){
          for(let x in resp.data.data.records){
            resp.data.data.records[x].selected = false;
            leads.data.push(resp.data.data.records[x])
          }
        }
        if(leads.page === 1){
          leads.totalRecords = resp.data.data.total_records
        }
      }).catch(error => {
            console.log(error)
      }).then(()=> {
        cancelToken = false
        spinners.gettingLeads = false
      })
    }
    onIonViewWillEnter(()=>{ getLeads() })

    let isPopoverOpen = ref(false)
    let leadTemp = ref(null)
    const setLeadTempInfo = (l)=>{
      leadTemp.value = l
      isPopoverOpen.value = true
    }

    const performBulkAction = (action)=>{
      const list = getSelectedRecordsForBulkAction(leads.data)
      if(!list){
        return;
      }
      if( action === 'export' ){
        leadsCSVExport.selectedLeads.splice(0)
        for(let x in list) leadsCSVExport.selectedLeads.push(list[x].AL_id)
        leadsCSVExport.modalOpen = true
      }
    }

    const allSelected = computed({
      get:()=> {
        for(let x in leads.data){
          if(!leads.data[x].selected){
            return false
          }
        }
        return true
      },
      set: (s)=> selectAll(leads.data, s)
    })

    return {
      allSelected,
      isDesktop,
      leadsCSVExport,
      searchFilter,
      paginationCallback,

      searchBarActive,

      getLeads,
      leads,
      performBulkAction,
      searchRecords,
      spinners,
      isPopoverOpen,
      selectAll,
      setLeadTempInfo,
      leadTemp,
      eyeIcon: eyeOutline,
      phoneCallIcon: callOutline,
    }
  }
});

</script>
<style>
.popover_class{
  --min-width: 70%
}
</style>
